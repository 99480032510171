import React from 'react';

import Button from '@atoms/new/button';

import SettingBlock from '@components/Presentation/main/Settings/units/SettingBlock';

const ChoiceCircleOptions = ({
  options,
  setOptions
}) => {
  return (
    <>
      <span className="block mt-4"></span>
      <SettingBlock
        title="Entries"
        tooltip="Add options to be randomly chosen by the spinner wheel."
        titleMargin="sm"
        hasBorderTop={false}
        hasParentBg
      >
        <div className="w-full">
          <textarea
            className="w-full h-31 p-2 rounded-md border border-grey-400 resize-none focus:outline-blue-500"
            placeholder="Entry 1&#10;Entry 2&#10;Entry 3"
            value={options.map((option) => option.body).join('\n')}
            onChange={(e) => setOptions(e.target.value.split('\n').map((body) => ({ body })))}
          />
        </div>
      </SettingBlock>
    </>
  );
};

export default ChoiceCircleOptions;
