import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';

import SlideImage from '@components/Presentation/main/Sidebar/SlideImage';

import { interactionsName } from '@services/utils.service';

const InteractionHeader = ({ featureType, selectedPanelBtn, setSelectedPanelBtn }) => {
  return (
    <div className="w-full flex flex-col">
      <div className="flex gap-2 items-center pb-4">
        <SlideImage slideType={featureType} color='#707070' />
        <div className="text-xl">{interactionsName[featureType]?.name}</div>
      </div>
      <div className="w-full flex items-center cursor-pointer">
        <h3
          className={`flex-1 text-base font-semibold py-2 text-center ${selectedPanelBtn === 'Content' ? 'border-b border-primary-500 text-primary-500' : 'border-b border-grey-200'}`}
          onClick={() => setSelectedPanelBtn('Content')}
        >
          <div className="w-full flex items-center justify-center gap-2">
            <EditIcon /><div>Content</div>
          </div>
        </h3>
        <h3
          className={`flex-1 text-base font-semibold py-2 text-center ${selectedPanelBtn === 'Themes' ? 'border-b border-primary-500 text-primary-500' : 'border-b border-grey-200'}`}
          onClick={() => setSelectedPanelBtn('Themes')}
        >
          <div className="w-full flex items-center justify-center gap-2">
            <FormatPaintIcon /><div>Themes</div>
          </div>
        </h3>
      </div>
    </div>
  );
};

export default InteractionHeader;
