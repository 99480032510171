import { getFeaturesCommentDetails } from '@services/youtube-platform.service';
import { chromaPaletteParser } from '../helper/chromaPaletteParser';
import { colorPalette } from '@lib/constants';
import { getMCQWinners } from '@services/presentation-live';
import { ALL_MCQ_SCORERS_ID } from '@components/AdhocInteractionPopup';

const MAX_LIMIT_FOR_TEXT_IN_SECTORS = 75;
const MAX_LIMIT_FOR_BLACK_LINE = 50;
const reds = ['#D14944', '#FF0C0C', '#FF2727', '#FF4242', '#FF5D5D', '#FF7878', '#FF9393', '#FFAEAE', '#FFC9C9', '#FFE4E4'];
const oranges = ['#e89a3a', '#FF9E0C', '#FFA927', '#FFB342', '#FFBE5D', '#FFC978', '#FFD493', '#FFDFAE', '#FFE9C9', '#FFF4E4'];
const yellows = ['#e8ca03', '#FFE70C', '#FFE927', '#FFEC42', '#FFEF5D', '#FFF278', '#FFF493', '#FFF7AE', '#FFFAC9', '#FFFCE4'];
const greens = ['#7eb330', '#7AFE13', '#89FE2D', '#98FE47', '#A7FE62', '#B5FE7C', '#C4FF96', '#D3FFB0', '#E2FFCB', '#F0FFE5'];
const blueGreens = ['#01CFDC', '#01EAF9', '#19F0FE', '#35F2FE', '#52F4FE', '#6FF6FE', '#8CF8FF', '#A9F9FF', '#C5FBFF', '#E2FDFF'];
const blues = ['#3d7ee1', '#0601F9', '#1D19FE', '#0501DC', '#5652FE', '#726FFE', '#8E8CFF', '#AAA9FF', '#C7C5FF', '#E3E2FF']; // 0 and 3 are switched
const pinks = ['#e8005b', '#FE13E6', '#FE2DE9', '#FE47EC', '#FE62EF', '#FE7CF1', '#FF96F4', '#FFB0F7', '#FFCBFA', '#FFE5FC'];
const purples = ['#895f9c', '#9A1AFF', '#A533FF', '#B14DFF', '#BC66FF', '#C780FF', '#D299FF', '#DDB3FF', '#E9CCFF', '#F4E5FF'];
const colorsOrder = [reds, oranges, yellows, greens, blueGreens, blues, pinks, purples];

// const colors = Array.from({ length: 1 }, (_, i) => colorsOrder.map((row) => row[i])).flat();
const colors = [...colorPalette];

const truncateText = (radius, text, ctx) => {
  const maxWidth = 0.7 * radius;
  const { width } = ctx.measureText(text);

  if (width < maxWidth) {
    return text;
  }

  const ellipses = '...';
  let truncatedWord = '';
  for (let i = 0; i < text.length; i += 1) {
    const char = text[i];
    if (ctx.measureText(truncatedWord + char + ellipses).width > maxWidth) {
      truncatedWord += ellipses;
      break;
    }
    truncatedWord += char;
  }
  return truncatedWord;
};

const addTextToSector = ({
  ctx, centerX, centerY, startAngle, endAngle, text, canvasSide, radius, optionsLength
}) => {
  ctx.moveTo(centerX, centerY);
  ctx.translate(centerX, centerY);
  ctx.rotate((startAngle + endAngle) / 2);
  // ctx.rotate(Math.PI + (startAngle + endAngle) / 2); // Text Circumference to center
  ctx.fillStyle = 'black';
  ctx.textAlign = 'start';
  // ctx.textAlign = 'end'; // Text Circumference to center
  ctx.textBaseline = 'middle';
  let fontSize = canvasSide > 300 ? '16' : '10';
  const fontWeight = optionsLength <= 50 ? '500' : '400';
  if (optionsLength > 40) fontSize -= 2;
  if (optionsLength > 50) fontSize -= 2;
  if (optionsLength > 60) fontSize -= 2;
  if (optionsLength > 70) fontSize -= 2;
  ctx.font = `${fontWeight} ${fontSize}px Poppins`;
  const fillText = truncateText(radius, text, ctx);
  // const paddingFromCircumference = radius * 0.05; // Text Circumference to center
  const paddingFromCircumference = radius * 0.1;

  const requiredSpace = ctx.measureText(fillText).width;
  // Text is painted from center to circumference
  const x = (startAngle + endAngle + radius - requiredSpace - paddingFromCircumference);
  // const x = -radius + requiredSpace + paddingFromCircumference; // Text Circumference to center
  ctx.fillText(fillText, x, 0);
  ctx.restore();
};

const getFeatureComments = async ({
  question,
  streamId,
  hiddenData,
  setInteractionState,
  turncateWinningWheelOpt,
  setNoUser,
  setOpts,
  setNewWheelUser
}) => {
  const hiddenIds = hiddenData[question.id] || [];
  let { featureId, featureType } = question;
  const wheelUser = question.setting.wheelShowUser;

  if (featureId === ALL_MCQ_SCORERS_ID) featureId = undefined;

  let response;
  if (featureType === 'multipleChoice') {
    response = await getMCQWinners(streamId, question.setting.wheelMcqLimit, featureId);
  } else {
    response = await getFeaturesCommentDetails(streamId, featureId, wheelUser);
  }

  if (response.status) {
    if (response.entity.length) {
      const opt = response.entity.map((user) => {
        if (!hiddenIds.includes(user.id)) {
          return { id: user.id, body: turncateWinningWheelOpt(user.user) };
        }
        return undefined;
      }).filter((val) => val !== undefined);
      setOpts(opt);
      setNewWheelUser(response.entity.map((user) => (
        { id: user.id, body: turncateWinningWheelOpt(user.user) })));
      setNoUser(false);
      // eslint-disable-next-line no-param-reassign
      question.options = opt;
    } else {
      setInteractionState('no data');
      setNoUser(true);
      setOpts([]);
    }
  }
};

function drawSectors(i, ctx, centerX, centerY, sectorAngle, radius, opts, isResult, chromaColor) {
  const newColors = chromaPaletteParser().parse(colors, chromaColor);
  const startAngle = (i * sectorAngle);
  const endAngle = ((i + 1) * sectorAngle);
  const fillColor = isResult ? opts[i].color : newColors[i % newColors.length];
  ctx.beginPath();
  ctx.strokeStyle = opts.length <= MAX_LIMIT_FOR_BLACK_LINE ? 'black' : newColors[i % newColors.length];
  ctx.lineWidth = 2;
  ctx.moveTo(centerX, centerY);
  ctx.lineTo(
    centerX + (radius * Math.cos(startAngle)),
    centerY + (radius * Math.sin(startAngle))
  );
  ctx.arc(centerX, centerY, radius, startAngle, endAngle);
  ctx.fillStyle = fillColor;
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.save();
  return { startAngle, endAngle };
}

const drawWheel = (canvas, radius, opts, canvasSide, isResult, chromaColor) => {
  const ctx = canvas.getContext('2d');
  const pi = Math.PI;
  const sectorAngle = (2 * pi) / opts.length;
  const w = canvas.width;
  const h = canvas.height;
  const centerX = w / 2;
  const centerY = h / 2;

  // Outer Circle
  ctx.arc(centerX, centerY, radius + 10, 0, 2 * pi);
  ctx.fillStyle = 'white';
  ctx.fill();

  const addTextToSectors = opts.length <= MAX_LIMIT_FOR_TEXT_IN_SECTORS;
  for (let i = 0; i < opts.length; i += 1) { // Draw the wheel
    const { startAngle, endAngle } = drawSectors(
      i,
      ctx,
      centerX,
      centerY,
      sectorAngle,
      radius,
      opts,
      isResult,
      chromaColor
    );
    if (addTextToSectors) {
      addTextToSector({
        ctx,
        centerX,
        centerY,
        startAngle,
        endAngle,
        radius,
        canvasSide,
        text: opts[i].body,
        optionsLength: opts.length
      });
    }
  }

  // Inner Circle
  ctx.beginPath();
  ctx.lineWidth = 10;
  ctx.fillStyle = 'white';
  ctx.arc(centerX, centerY, 14, 0, 2 * pi);
  ctx.fill();
  ctx.closePath();
  // Inner Circle Border
  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = 'black';
  ctx.arc(centerX, centerY, 14, 0, 2 * pi);
  ctx.stroke();
  ctx.closePath();
};

export {
  truncateText, addTextToSector, drawWheel, colors, getFeatureComments
};
