import { getWithAuth } from '@services/http.service';
import { UrlParamsReplace } from '@services/url.service';

export const GET_MCQ_WINNERS = ({
  streamId,
  noOfWinners,
  featureId
}: {
  streamId: string;
  noOfWinners?: number;
  featureId?: string;
}) => UrlParamsReplace(`/winning-wheel/mcq-winners?streamId=:streamId&noOfWinners=:noOfWinners${featureId ? `&featureId=${featureId}` : ''}`, {
  streamId,
  noOfWinners
});

export const getMCQWinners = (streamId: string, noOfWinners?: number, featureId?: string) => getWithAuth(GET_MCQ_WINNERS({ streamId, noOfWinners, featureId }));
