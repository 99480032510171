import React, { useState, useEffect, useRef } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import Button from '@atoms/new/button';
import Input from '@atoms/Input';
import MaxLengthCounter from '@components/Presentation/atoms/MaxLengthCounter';

import SettingBlock from '@components/Presentation/main/Settings/units/SettingBlock';

import { calculateRemainingChars } from '@components/Presentation/helpers';

const PowerPollOptions = ({
  options,
  handleChangeOptionBody,
  addNewOption,
  deleteOption
}) => {
  const [isOptionFocused, setIsOptionFocused] = useState(undefined);

  const optionsRef = useRef(null);

  useEffect(() => {
    if (options.length > 2) {
      optionsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [options.length]);

  return (
    <SettingBlock
      title="Options"
      tooltip="Add options for your audience to vote on."
      titleMargin="sm"
      hasBorderTop={false}
      hasParentBg
    >
      {options.map((option, index) => (
        <div key={option.id}>
          <div className="flex gap-1 items-center">
            <Input
              placeholder={`Option ${index + 1}`}
              value={option.body}
              onChange={(e) => handleChangeOptionBody(index, e)}
              handleFocus={(v) => setIsOptionFocused(v ? index : undefined)}
              size="lg"
              maxLength={60}
              addOnRight={
                isOptionFocused === index ? (
                  <MaxLengthCounter
                    threshold={10}
                    value={calculateRemainingChars(option.body, 60)}
                  />
                ) : null
              }
            />

            <Button
              kind="tertiary"
              size="md"
              onClick={() => deleteOption(option)}
              isDisabled={options.length <= 2 && (index === 0 || index === 1)}
              isIconOnly
            >
              <CloseRoundedIcon fontSize="small" />
            </Button>
          </div>
          {calculateRemainingChars(option.body, 60) === 0 && (
            <p className="text-sm text-alert mt-1">You have reached the maximum length</p>
          )}
        </div>
      ))}
      <Button
        ref={optionsRef}
        kind="tertiary"
        size="sm"
        onClick={addNewOption}
        fullWidth
        startContent={<AddRoundedIcon fontSize="small" />}
        isDisabled={options.length >= 10}
      >
      Add option
      </Button>
    </SettingBlock>
  );
};

export default PowerPollOptions;
