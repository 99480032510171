import React, { useState, useRef, useEffect } from 'react';

import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesome';

import Button from '@atoms/new/button';
import Input from '@atoms/Input';
import MaxLengthCounter from '@components/Presentation/atoms/MaxLengthCounter';

import SettingBlock from '@components/Presentation/main/Settings/units/SettingBlock';

import { GenerateFeatureSuggestionsModal } from '@components/Presentation/ai/generate-feature-suggestions-modal';
import { calculateRemainingChars } from '@components/Presentation/helpers';

import PowerPollOptions from './PowerPollsOptions';
import MultipleChoiceOptions from './MultipleChoiceOptions';
import ChoiceCircleOptions from './ChoiceCircleOptions';

const InteractionQuestion = ({
  featureType,
  title,
  setTitle,
  options,
  setOptions,
  addNewOption,
  deleteOption,
  setting,
  updateSetting
}) => {
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const [showGenerateWithAIModal, setShowGenerateWithAIModal] = useState(false);

  const showGenerateWithAIButton = () => ['pollWithOptions', 'wordCloud', 'talkingTiles', 'transientThought', 'multipleChoice'].includes(featureType);

  const titleRef = useRef(null);

  const handleChangeOptionBody = (index, event) => {
    const updatedOptions = [...options];
    updatedOptions[index] = {
      ...updatedOptions[index],
      body: event.target.value
    };
    setOptions(updatedOptions);
  };

  const handleChangeOptionCheck = (index, e) => {
    const updatedOptions = [...options];
    updatedOptions[index] = {
      ...updatedOptions[index],
      checked: e.target.value
    };
    setOptions(updatedOptions);
  };

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, []);

  return <>
    <div className="mt-6 mb-2">
      {showGenerateWithAIButton() && <Button
        fullWidth
        kind="secondary"
        size="sm"
        onClick={() => setShowGenerateWithAIModal(true)}
        startContent={<AutoAwesomeRoundedIcon fontSize="small" className='text-primary-500'/>}
      >
        <span style={{
          background: 'linear-gradient(90deg, #FF0064 0%, #0086FF 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}>Generate with AI</span>
      </Button>}
    </div>

    <div className="border-1 rounded-md bg-grey-50 p-2.5 mb-6">
      <SettingBlock
        title="Heading"
        hasBorderTop={false}
        tooltip={
          <p>
          Add a prompt—such as a question <br /> or instruction—for your
          audience.
          </p>
        }
        titleMargin="sm"
        hasParentBg
      >
        <Input
          ref={titleRef}
          placeholder="Type a prompt or question..."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          size="lg"
          maxLength={255}
          handleFocus={setIsTitleFocused}
          addOnRight={
            isTitleFocused ? (
              <MaxLengthCounter
                value={calculateRemainingChars(title)}
              />
            ) : null
          }
        />
        {calculateRemainingChars(title) === 0 && (
          <p className="text-sm text-alert mt-1">
            You have reached the maximum length
          </p>
        )}
      </SettingBlock>

      <span className="block mt-4"></span>

      {(featureType === 'multipleChoice') && <MultipleChoiceOptions
        options={options}
        setting={setting}
        updateSetting={updateSetting}
        handleChangeOptionBody={handleChangeOptionBody}
        handleChangeOptionCheck={handleChangeOptionCheck}
        addNewOption={addNewOption}
        deleteOption={deleteOption}
      />}

      {(featureType === 'pollWithOptions') && <PowerPollOptions
        options={options}
        handleChangeOptionBody={handleChangeOptionBody}
        addNewOption={addNewOption}
        deleteOption={deleteOption}
      />}

      {(featureType === 'wheel') && <ChoiceCircleOptions
        options={options}
        setOptions={setOptions}
      />}

      <GenerateFeatureSuggestionsModal
        setQuestionTitle={(value) => setTitle(value)}
        setOptions={(options) => options.length && setOptions(options.map((opt, index) => ({ ...opt, option_number: index + 1 })))}
        feature={featureType}
        isOpen={showGenerateWithAIModal}
        onClose={() => setShowGenerateWithAIModal(false)}
      />
    </div>
  </>;
};

export default InteractionQuestion;
