import React, { useEffect, useRef } from 'react';
import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';

interface CheckboxProps {
  checked: boolean;
  label?: string;
  onChange: () => void;
  isInvalid?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  className?: string;
  size?: 'lg' | 'md';
}
function Checkbox({
  checked,
  label = '',
  onChange,
  isInvalid = false,
  disabled = false,
  indeterminate = false,
  className = '',
  size = 'md'
}: CheckboxProps) {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  const getSize = () => {
    switch (size) {
      case 'lg':
        return 'w-5 h-5';
      case 'md':
      default:
        return 'w-4 h-4';
    }
  };
  const getLabelSize = () => {
    switch (size) {
      case 'lg':
        return 'text-base';
      case 'md':
      default:
        return 'text-sm';
    }
  };

  const getSVGSize = () => {
    switch (size) {
      case 'lg':
        return { width: '12', height: '8' };
      case 'md':
      default:
        return { width: '10', height: '7' };
    }
  };

  const getCheckboxColor = () => {
    if (disabled) return 'border-grey-600';
    if (isInvalid) return 'checked:bg-red-500 indeterminate:bg-red-500 border-red-500';
    if (checked) return 'checked:bg-blue-500 checked:border-0 indeterminate:bg-blue-500 indeterminate:border-0';
    return 'border-grey-300 indeterminate:bg-blue-500 indeterminate:border-0';
  };

  return (
    <div className={`flex ${className} ${disabled ? 'opacity-30 pointer-events-none' : ''}`}>
      <label className="whitespace-nowrap flex gap-2 items-center relative cursor-pointer">
        <input
          ref={ref}
          name={`${label}`} type="checkbox" className={`relative peer shrink-0 
      appearance-none ${getSize()} ${getCheckboxColor()} border  rounded bg-white cursor-pointer
      `} checked={checked} onClick={onChange} disabled={disabled} />

        {label && <span className={getLabelSize()}>{label}</span>}

        <span className={`absolute hidden peer-checked:flex items-center justify-center ${getSize()}`}>
          <svg width={getSVGSize().width} height={getSVGSize().height} viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.24503 1.59009L3.99503 5.84009L1.74503 3.59009" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
        <span className={`absolute hidden peer-indeterminate:flex items-center justify-center ${getSize()}`}>
          <HorizontalRuleRoundedIcon className="text-white" fontSize='inherit' />
        </span>
      </label>
    </div>
  );
}

export default Checkbox;
