import React from 'react';

import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Image from 'next/image';

import Dropdown from '@atoms/Dropdown.new';

import SettingSwitch from '@molecules/SettingSwitch';
import SettingBlock from '@components/Presentation/main/Settings/units/SettingBlock';

import SlideImage from '@components/Presentation/main/Sidebar/SlideImage';

import { TOOLTIP_CONTENT_MAP, SHOW_USERNAME_TOOLTIP_CONTENT_MAP, linkCategories, getImage, convertToPascalCase, playbackOptions, mapContinents, getOptionName } from '@services/interactions.service';
import { interactionsName } from '@services/utils.service';
import { ALL_MCQ_SCORERS_ID } from '@components/AdhocInteractionPopup';

const InteractionSetting = ({
  featureType,
  setting,
  updateSetting,
  winnerWheelsData,
  selectedInteraction,
  setSelectedInteraction
}) => {
  const wheelShowUserOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

  const showAudienceResponses = () => !['wheel', 'newWheel', 'multipleChoice', 'linkLibrary', 'fantasticFans'].includes(featureType);
  const showResultLayout = () => ['freeForm', 'pollWithOptions', 'wordCloud', 'ratingPolls', 'multipleChoice', 'textTrack'].includes(featureType);
  const showLanguage = () => ['freeForm', 'pollWithOptions', 'wordCloud', 'talkingTiles', 'multipleChoice', 'textTrack'].includes(featureType);

  const tooltipContent = TOOLTIP_CONTENT_MAP[featureType] || 'StreamAlive will count multiple responses per participant.';
  const showUserNameTooltipContent = SHOW_USERNAME_TOOLTIP_CONTENT_MAP[featureType] || 'Each title will show the username of the commenter.';

  const hasOneAnswerPerPerson = () => ([
    'map',
    'freeForm',
    'pollWithOptions',
    'wordCloud',
    'emojisEverywhere',
    'transientThought',
    'talkingTiles'
  ].includes(featureType));

  const hasCombineWords = () => ([
    'freeForm',
    'wordCloud'
  ].includes(featureType));

  const hasSplitPhrasesIntoWords = () => ([
    'wordCloud',
    'textTrack'
  ].includes(featureType));

  const hasCommentsOverlay = () => ([
    'map',
    'freeForm',
    'pollWithOptions',
    'wordCloud',
    'emojisEverywhere',
    'ratingPolls'
  ].includes(featureType));

  const hasShowUserName = () => ([
    'transientThought',
    'talkingTiles',
    'Quick Questions'
  ].includes(featureType));

  const hasDisplayStyle = () => ([
    'freeForm',
    'pollWithOptions',
    'ratingPolls',
    'multipleChoice'].includes(featureType));

  const hasShowSomeWordsVertically = () => ([
    'wordCloud',
    'textTrack'
  ].includes(featureType));

  const hasDefaultView = () => (['map'].includes(featureType));
  const hasShowLatestCommentsFirst = () => (['transientThought'].includes(featureType));
  const hasOptionsList = () => (['pollWithOptions'].includes(featureType));
  const hasShowResponses = () => (['multipleChoice'].includes(featureType));
  const hasPlaybackSpeed = () => (['transientThought'].includes(featureType));

  const multipleChoiceFilterOptions = ['All', 10, 20];

  const splitWordsKey = featureType === 'textTrack' ? 'textTrackSplitWords' : 'SplitWords';
  const usernameKey = featureType === 'transientThought' ? 'transientThoughtShowUsername' : 'showUserName';

  const getInteractionName = (interaction) => {
    if (interaction?.type === 'multipleChoice' && interaction?.id === ALL_MCQ_SCORERS_ID) return 'Your top scorers';
    return interactionsName[interaction?.type]?.name || 'All Interactions';
  };

  const handleAllowMultipleResponses = (key, value) => {
    updateSetting(key, !value);
  };

  return <>
    {showAudienceResponses() && <SettingBlock
      title="Audience responses"
      hasBorderTop
    >
      {hasOneAnswerPerPerson() && (
        <SettingSwitch
          label="Allow multiple responses"
          tooltipContent={tooltipContent}
          settingKey="oneUoneV"
          settingValue={!setting.oneUoneV}
          onChangeHandler={handleAllowMultipleResponses}
        />
      )}

      {hasCombineWords() && (
        <SettingSwitch
          label={featureType === 'freeForm' ? 'Allow minor typos' : 'Combine similar answers'}
          tooltipContent={featureType === 'freeForm' ? 'Similar responses from the chat will be counted together.' : 'Similar responses will be grouped, with the added flexibility of accommodating minor typos.'}
          settingKey="CombineWords"
          settingValue={setting.CombineWords}
          onChangeHandler={updateSetting}
        />
      )}

      {hasSplitPhrasesIntoWords() && (
        <SettingSwitch
          label="Split phrases into words"
          tooltipContent="All responses will show up as individual words."
          settingKey={splitWordsKey}
          settingValue={setting?.[splitWordsKey]}
          onChangeHandler={updateSetting}
        />
      )}

      {hasCommentsOverlay() && (
        <SettingSwitch
          label="Show comments overlay"
          tooltipContent="Show audience responses as a callout in the lower right corner of the presentation."
          settingKey="CommentsOverlay"
          settingValue={setting.CommentsOverlay}
          onChangeHandler={updateSetting}
        />
      )}

      {hasShowUserName() && (
        <SettingSwitch
          label="Show username"
          tooltipContent={showUserNameTooltipContent}
          settingKey={usernameKey}
          settingValue={setting?.[usernameKey]}
          onChangeHandler={updateSetting}
        />
      )}

      {hasDefaultView() && <SettingBlock
        title="Map view"
        tooltip="Initial map view to be shown. You can still pan and zoom over the entire map during a presentation."
        titleMargin="sm"
        hasBorderTop
        addPadding={false}
      >
        <Dropdown
          className="z-[1]"
          width="w-full"
          trigger={({ onClick }) => (
            <button
              className="w-full h-10 border border-grey-200 rounded-md px-3 flex items-center gap-3"
              onClick={onClick}
            >
              {getOptionName(setting)}
              <div className="ml-auto">
                <ExpandMoreRoundedIcon />
              </div>
            </button>
          )}
          value={setting.DefaultView || 'world'}
          onChange={(value) => updateSetting('DefaultView', value)}
        >
          {mapContinents.map((option) => (
            <Dropdown.Item
              key={option.value}
              value={option.value}
            >
              {option.name}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </SettingBlock>
      }

      {hasShowLatestCommentsFirst() && (
        <SettingSwitch
          label="Show recent comments first"
          tooltipContent="All responses will show up as individual words."
          settingKey="showLatestCommentsFirst"
          settingValue={setting.showLatestCommentsFirst}
          onChangeHandler={updateSetting}
        />
      )}

    </SettingBlock>}

    {(featureType === 'newWheel') && <SettingBlock
      title="Select an interaction"
      tooltip="Select an interaction"
      titleMargin="sm"
      hasBorderTop
    >
      <Dropdown
        className="z-[10]"
        width="w-full"
        trigger={({ onClick }) => (
          <button
            className={`w-full h-[64px]  border border-grey-200 rounded-md px-3 flex items-center gap-3  ${setting.enableNames ? 'bg-grey-30 opacity-30 cursor-not-allowed' : ''}`}
            onClick={setting.enableNames ? undefined : onClick}
          >
            <div className="flex gap-3 py-2 px-2">
              <div className="flex items-center justify-center text-grey-900">
                <SlideImage slideType={selectedInteraction.type} color='#707070' />
              </div>
              <div className="flex text-sm flex-initial flex-col line-clamp-1">
                <div className="text-left line-clamp-1">{getInteractionName(selectedInteraction)}
                  <span className="text-[#707070]"> • </span>
                  {selectedInteraction?.totalCommenters} {selectedInteraction?.totalCommenters === 1 ? 'commenter' : 'commenters'}
                </div>
                <div className="text-left line-clamp-1">{selectedInteraction.title || 'No Title for this Interaction'}</div>
              </div>
            </div>

            <div className="ml-auto">
              <ExpandMoreRoundedIcon />
            </div>
          </button>
        )}
        value={selectedInteraction}
        onChange={(value) => setSelectedInteraction(value)}
      >
        {winnerWheelsData.map((data) => (
          <Dropdown.Item
            key={data.id}
            value={data}
            onClick={() => setSelectedInteraction(data)}
          >
            <div className="flex gap-3">
              <div className="flex items-center justify-center text-grey-900">
                <SlideImage slideType={data.type} color='#707070' />
              </div>
              <div className="flex text-sm flex-initial flex-col line-clamp-1">
                <div className="text-left line-clamp-1">{getInteractionName(data)}
                  <span className="text-[#707070]"> • </span>
                  {data?.totalCommenters} {data?.totalCommenters === 1 ? 'commenter' : 'commenters'}
                </div>
                <div className="text-left line-clamp-1">{data.title || 'No Title for this Interaction'}</div>
              </div>
              <div className="flex items-center justify-center text-grey-900">
                {data.id === selectedInteraction.id ? (

                  <DoneIcon
                    fontSize="small"
                  />
                ) : <div className='w-5 h-5'></div>}
              </div>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown>
    </SettingBlock>}

    {(selectedInteraction.type !== 'multipleChoice' && featureType === 'newWheel' && !setting.enableNames) && <SettingBlock
      title="Filter users with at least..."
      tooltip="Choose the minimum number of comments a participant must have typed to be included."
      titleMargin="sm"
      hasBorderTop
    >
      <Dropdown
        className="z-[1]"
        width="w-full"
        trigger={({ onClick }) => (
          <button
            className="w-full h-10 border border-grey-200 rounded-md px-3 flex items-center gap-3"
            onClick={onClick}
          >
            {setting.wheelShowUser} comment
            <div className="ml-auto">
              <ExpandMoreRoundedIcon />
            </div>
          </button>
        )}
        value={setting.wheelShowUser || '1'}
        onChange={(value) => updateSetting('wheelShowUser', value)}
      >
        {wheelShowUserOptions.map((option) => (
          <Dropdown.Item
            key={option}
            value={option}
          >
            {option} comment
          </Dropdown.Item>
        ))}
      </Dropdown>
    </SettingBlock>}

    {(selectedInteraction.type === 'multipleChoice' && featureType === 'newWheel' && !setting.enableNames) && <SettingBlock
      title="Choose from the list of users..."
      titleMargin="sm"
      hasBorderTop
    >
      <Dropdown
        className="z-[1]"
        width="w-full"
        trigger={({ onClick }) => (
          <button
            className="w-full h-10 border border-grey-200 rounded-md px-3 flex items-center gap-3"
            onClick={onClick}
          >
            {setting.wheelMcqLimit !== 'All' && 'Top'} {setting.wheelMcqLimit || 'All'} {setting.wheelMcqLimit === 'All' ? 'users' : 'highest scorers'}
            <div className="ml-auto">
              <ExpandMoreRoundedIcon />
            </div>
          </button>
        )}
        value={setting.wheelMcqLimit || 'All'}
        onChange={(value) => updateSetting('wheelMcqLimit', value)}
      >
        {multipleChoiceFilterOptions.map((option) => (
          <Dropdown.Item
            key={option}
            value={option}
          >
            {option !== 'All' && 'Top'} {option} {option === 'All' ? 'users' : 'highest scorers'}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </SettingBlock>}

    {/* {(featureType === 'newWheel') && <>
      <div className='flex flex-col border-t border-grey-100 p-6 gap-3'>
        <SettingSwitch
          label="Names"
          tooltipContent="Use a pre-uploaded list of names instead of people who commented during the session."
          settingKey="enableNames"
          settingValue={setting.enableNames}
          onChangeHandler={updateSetting}
        />
        <div className="w-full">
          <textarea
            className="w-full h-31 p-2 rounded-md border border-grey-400 resize-none focus:outline-blue-500"
            placeholder="Name 1&#10;Name 2&#10;Name 3"
            value={options.map((option) => option.body).join('\n')}
            onChange={(e) => setOptions(e.target.value.split('\n').map((body) => ({ body })))}
            disabled={!setting.enableNames}
          />
        </div>
      </div>
    </>} */}

    {showResultLayout() && <SettingBlock
      title="Result Layout"
    >
      {hasDisplayStyle() && <SettingSwitch
        label="Show as percentages (%)"
        tooltipContent="When enabled, the percentage of votes is shown. Else, the number of votes are shown."
        settingKey="DisplayStyle"
        settingValue={setting.DisplayStyle === 'percentage'}
        onChangeHandler={updateSetting}
      />}

      {hasOptionsList() && (
        <SettingSwitch
          label="Show numerical options"
          tooltipContent="When enabled, options are labelled as 1, 2, 3. Else, as A, B, C."
          settingKey="OptionsList"
          settingValue={setting.OptionsList === 'number'}
          onChangeHandler={updateSetting}
        />
      )}

      {hasShowSomeWordsVertically() && (
        <SettingSwitch
          label="Show some words vertically"
          tooltipContent="Allow some words to be displayed vertically."
          settingKey="Vertical"
          settingValue={setting.Vertical}
          onChangeHandler={updateSetting}
        />
      )}

      {hasShowResponses() && (
        <SettingSwitch
          label="Show responses"
          tooltipContent="Responses will be reflected in the poll in real-time"
          settingKey="showResponses"
          settingValue={setting.showResponses}
          onChangeHandler={updateSetting}
        />
      )}

    </SettingBlock>}

    {(featureType === 'linkLibrary') && <SettingBlock
      title="Select platform"
      tooltip="All links are shown by default. Choose to filter by website if needed."
      titleMargin="sm"
      hasBorderTop
    >
      <Dropdown
        trigger={({ onClick }) => (
          <button
            onClick={onClick}
            className="w-full h-10 border border-grey-200 rounded-md px-3 flex items-center gap-3"
          >
            <div className="w-5 h-5">
              <Image
                src={getImage(setting).url}
                alt={getImage(setting).name}
                width={20}
                height={20}
              />
            </div>
            {convertToPascalCase(setting.LinkType)}
            <div className="ml-auto">
              <ExpandMoreRoundedIcon />
            </div>
          </button>
        )}
        width="w-full"
        value={setting.LinkType || 'All'}
        onChange={(value) => updateSetting('LinkType', value)}
      >
        {Object.entries(linkCategories).map(([k, v]) => (
          <Dropdown.Item
            key={k}
            value={k}
            addonLeft={
              <Image
                src={v.img}
                alt={v.name}
                width={20}
                height={20}
              />
            }
          >
            {v.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </SettingBlock>}

    {hasPlaybackSpeed() && <SettingBlock
      title="Playback Speed"
      tooltip="This controls the speed at which new comments are shown. Larger comments will be onscreen for longer."
    >
      <Dropdown
        trigger={({ onClick }) => (
          <button
            className="w-full h-10 border border-grey-200 rounded-md px-3 flex items-center gap-3"
            onClick={onClick}
          >
            {setting.ttPlaybackSpeed && playbackOptions[setting.ttPlaybackSpeed]}
            <div className="ml-auto">
              <ExpandMoreRoundedIcon />
            </div>
          </button>
        )}
        width="w-full"
        value={setting.ttPlaybackSpeed || 'default'}
        onChange={(value) => updateSetting('ttPlaybackSpeed', value)}
      >
        {Object.keys(playbackOptions).map((key) => (
          <Dropdown.Item
            key={key}
            value={key}
          >
            {playbackOptions[key]}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </SettingBlock>}

    {showLanguage() && <SettingBlock
      title="Language"
      itemGap="sm"
    >
      <SettingSwitch
        label="Filter out profanity"
        tooltipContent="Any comments containing explicit profanity (English) will not be shown."
        settingKey="ProfanityFilter"
        settingValue={setting.ProfanityFilter}
        onChangeHandler={updateSetting}
      />
      <p className="text-xs w-[80%]">
      Help us improve our profanity filtering by emailing us at <a href="mailto:someone@example.com" className='text-blue-600'>hi@streamalive.com</a>
      </p>
    </SettingBlock>}

  </>;
};

export default InteractionSetting;
