import React, { useState, useEffect, useRef } from 'react';

import {
  HelpRounded,
  CloseRounded,
  AddRounded
} from '@mui/icons-material';

import Button from '@atoms/new/button';
import Input from '@atoms/Input';
import Checkbox from '@atoms/Checkbox.new';
import PrefixInput from '@atoms/new/input';
import Tooltip from '@components/Presentation/atoms/Tooltip';
import Banner from '@components/Presentation/atoms/Banner';
import { SelectItem, Select } from '@atoms/new/select';
import MaxLengthCounter from '@components/Presentation/atoms/MaxLengthCounter';

import SettingBlock from '@components/Presentation/main/Settings/units/SettingBlock';
import OptionOrderType from '@components/Presentation/main/Settings/units/OptionOrderType';

import { calculateRemainingChars } from '@components/Presentation/helpers';
import { OPTIONS_LABEL } from '@services/interactions.service';

const MultipleChoiceOptions = ({
  options,
  setting,
  updateSetting,
  handleChangeOptionBody,
  handleChangeOptionCheck,
  addNewOption,
  deleteOption
}) => {
  const [isOptionFocused, setIsOptionFocused] = useState(undefined);

  const optionsRef = useRef(null);

  const onPrefixChange = (e) => {
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (
      e.target.value.length > 4 ||
      specialCharacterRegex.test(e.target.value) ||
      /\s/.test(e.target.value)
    ) return;

    updateSetting('prefix', e.target.value);
  };

  const isAnyOptionChecked = options?.some(
    (option) => option.checked
  );

  useEffect(() => {
    if (options.length > 2) {
      optionsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [options.length]);

  return (
    <SettingBlock
      title="Options"
      tooltip="Add options for your audience to vote on."
      titleMargin="sm"
      hasBorderTop={false}
      hasParentBg
      rightContent={
        <span>
          <p className="text-xs font-semibold flex gap-2">
              Prefix
            <Tooltip content={'Responses will only be accepted if they start with the provided prefix (e.g., "Q").'}>
              <HelpRounded fontSize="inherit" color="disabled" />
            </Tooltip>
          </p>
          <p className="text-[11px] font-Inter text-grey-500">(Optional)</p>
        </span>
      }
    >
      <div className="flex w-full items-center gap-3  mt-1">
        <p className="font-inter text-sm text-grey-900 font-medium  flex flex-1">Label:</p>
        <div className="flex  gap-x-2">
          <Select
            label=""
            aria-label="Label"
            className="min-w-[104px]"
            selectedKeys={[setting.optionOrderType || 'number']}
            onChange={(e) => e.target.value && updateSetting('optionOrderType', e.target.value)}
          >
            {OPTIONS_LABEL.map((option) => (
              <SelectItem key={option.key} value={option.key}>
                {option.label}
              </SelectItem>
            ))}
          </Select>

          <PrefixInput
            placeholder="-"
            value={setting.prefix}
            inputClassName="text-center"
            className="max-w-[50px] bg-white   "
            onChange={onPrefixChange}
            size="lg"
          />
        </div>
      </div>
      <p className="font-inter text-sm text-grey-900 font-medium">List:</p>
      {options?.map((option, index) => (
        <div key={option.id}>
          <div className="flex gap-2  items-center">
            <Checkbox
              checked={!!option.checked}
              size="lg"
              onChange={() => {
                const e = { target: { value: !option.checked } };
                handleChangeOptionCheck(index, e);
              }}
            />

            <OptionOrderType
              index={index}
              type={setting.optionOrderType || 'number'}
              prefix={setting.prefix}
            />

            <Input
              placeholder={`Option ${index + 1}`}
              value={option.body}
              onChange={(e) => handleChangeOptionBody(index, e)}
              handleFocus={(v) => setIsOptionFocused(v ? index : undefined)}
              size="lg"
              maxLength={60}
              addOnRight={
                isOptionFocused === index ? (
                  <MaxLengthCounter
                    threshold={10}
                    value={calculateRemainingChars(option.body, 60)}
                  />
                ) : null
              }
            />

            <Button
              kind="tertiary"
              size="md"
              onClick={() => deleteOption(option)}
              isDisabled={options.length <= 2 && (index === 0 || index === 1)}
              isIconOnly
            >
              <CloseRounded fontSize="small" />
            </Button>
          </div>
          {calculateRemainingChars(option.body, 60) === 0 && (
            <p className="text-sm text-alert mt-1">You have reached the maximum length</p>
          )}
        </div>
      ))}
      <Button
        ref={optionsRef}
        kind="tertiary"
        size="sm"
        onClick={addNewOption}
        fullWidth
        startContent={<AddRounded fontSize="small" />}
        isDisabled={options.length >= 10}
      >
        Add option
      </Button>
      <Banner
        open={!isAnyOptionChecked}
        key={'2'}
        content="Choose one correct option"
      />
    </SettingBlock>
  );
};

export default MultipleChoiceOptions;
